import { useEffect, useState } from "react";
import { FormButton } from "../../../ui";
import "./ai-job-researcher.scss";
import { IResponseError, IUser } from "../../../data-access";
import {
  handleFailedRequest,
  notify,
  updateExtensionCookies,
} from "../../../util";
import { billingApi, usersApi } from "../../../api";
import { useDispatch } from "react-redux";
import { storeAuthActions } from "../../../store/slices/auth";
import { storeBillingActions } from "../../../store/slices/billing";

const baseClass = "ai-job-researcher";

interface IAIJobResearcherProps {
  onLoading: any;
}

export const AIJobResearcher = ({
  onLoading,
}: IAIJobResearcherProps): JSX.Element => {
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isPaid, setIsPaid] = useState<boolean>(false);

  useEffect(() => {
    setIsProcessing(true);
    onLoading(true);
    usersApi
      .getMe()
      .then((response: { data: IUser }) => {
        const userData = { user: response.data };
        dispatch(storeAuthActions.setUserData(userData));
        dispatch(
          storeBillingActions.update({ discount: response.data?.promocode })
        );
        setIsPaid(response.data.bot_subscription_is_active);
        updateExtensionCookies(userData);
      })
      .catch()
      .finally(() => {
        setIsProcessing(false);
        onLoading(false);
      });
  }, [dispatch, isPaid]);

  const handlePay = (): void => {
    setIsProcessing(true);
    onLoading(true);

    billingApi
      .getBotPaymentLink()
      .then((response: { data: { payment_url: string } }) => {
        if (response.data.payment_url) {
          window.location.href = response.data.payment_url;
        } else {
          notify(
            "error",
            "Payment Error: Please contact our support team via the chat."
          );
        }
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => {
        setIsProcessing(false);
        onLoading(false);
      });
  };

  return (
    <div className={`${baseClass}`}>
      {!isPaid ? (
        <FormButton disabled loading={isProcessing} onClick={handlePay}>
          Connect
        </FormButton>
      ) : (
        <div className={`${baseClass}__available`}>Connected</div>
      )}
    </div>
  );
};
