import React, { FocusEvent, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { usersApi } from "../../api";
import {
  APP_ROUTES,
  ERROR_MESSAGES,
  IAuthResponse,
  IResponseError,
  VALID_EMAIL_REGEX,
  VALIDATION_MESSAGES,
} from "../../data-access";
import { ExtensionInfo, FormInput, FormButton, BrandLogo } from "../../ui";
import {
  notify,
  handleFailedRequest,
  sanitizeEmail,
  cookie,
  encryption,
  updateExtensionCookies,
} from "../../util";
import * as yup from "yup";
import "./login.scss";
import {
  CredentialResponse,
  GoogleLogin,
  GoogleOAuthProvider,
} from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { useWindowDimensions } from "../../hooks";
import { storeBillingActions } from "../../store/slices/billing";
import { useDispatch } from "react-redux";
import { storeAuthActions } from "../../store/slices/auth";

interface ILoginFormData {
  email: string;
}

interface IGoogleAuthInfo {
  name: string;
  email: string;
  picture: string;
  jti: string;
}

const validationScheme = yup
  .object({
    email: yup
      .string()
      .required()
      .matches(VALID_EMAIL_REGEX, VALIDATION_MESSAGES.INVALID_EMAIL),
  })
  .required();

export const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isProcessingRequest, setIsProcessingRequest] =
    useState<boolean>(false);
  const maxGoogleButtonWidth = 380;
  const [googleButtonWidth, setGoogleButtonWidth] =
    useState<number>(maxGoogleButtonWidth);

  const { width: screenWidth } = useWindowDimensions();
  const contentRef = useRef<HTMLDivElement>(null);

  const form = useForm<ILoginFormData>({
    mode: "onBlur",
    defaultValues: {
      email: "",
    },
    resolver: yupResolver(validationScheme),
  });
  const {
    control,
    resetField,
    handleSubmit,
    setValue,
    formState: { errors, dirtyFields },
  } = form;

  useEffect(() => {
    if (contentRef.current) {
      if (contentRef.current.clientWidth < maxGoogleButtonWidth) {
        setGoogleButtonWidth(contentRef.current.clientWidth);
      } else {
        setGoogleButtonWidth(maxGoogleButtonWidth);
      }
    }
  }, [screenWidth]);

  const handleFormSubmit = (formFields: ILoginFormData): void => {
    setIsProcessingRequest(true);

    usersApi
      .authenticate(formFields.email)
      .then(() => {
        notify("success", "Please check your email to confirm the login");
        resetField("email");
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => setIsProcessingRequest(false));
  };

  const handleGoogleFormSubmit = (formFields: CredentialResponse): void => {
    setIsProcessingRequest(true);

    usersApi
      .googleAuthenticate(formFields.credential)
      .then((response: IAuthResponse) => {
        const authDetails = {
          access_token: response.access_token,
          refresh_token: response.refresh_token,
          user: response.user,
        };

        dispatch(storeAuthActions.setUserData(authDetails));

        if (response.user.promocode) {
          dispatch(
            storeBillingActions.update({ discount: response.user.promocode })
          );
        }

        cookie.set("exSM", encryption.generateHash("authorized"));
        updateExtensionCookies(authDetails);
        navigate(APP_ROUTES.dashboard);
      })
      .catch((error: IResponseError) => {
        handleFailedRequest(error.status, error.text, error.details);
      })
      .finally(() => setIsProcessingRequest(false));
  };

  const handleEmailInput = (e: FocusEvent<HTMLInputElement>): void => {
    const sanitizedEmail = sanitizeEmail(e?.target?.value);
    setValue("email", sanitizedEmail, {
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const isFormValid =
    Object.keys(dirtyFields).length > 0 && !errors?.email?.message;

  return (
    <div className="login">
      <div className="login__column login__column--left">
        <BrandLogo />
        <div ref={contentRef} className="login__content">
          <h1 className="login__title">Sign in</h1>
          <p className="login__description">
            Connect using the email address. You will receive a link in your
            inbox.
          </p>
          <div
            className={`google-provider ${
              isProcessingRequest ? "google-provider--disabled" : ""
            }`}
          >
            <GoogleOAuthProvider
              clientId={`${process.env.REACT_APP_AUTH_CLIENT_ID}`}
            >
              <GoogleLogin
                size="large"
                context="signup"
                width={`${googleButtonWidth}px`}
                locale={"en"}
                onSuccess={(response: CredentialResponse) => {
                  handleGoogleFormSubmit(response);
                }}
                onError={() => {
                  notify("error", ERROR_MESSAGES.GENERIC);
                }}
              />
            </GoogleOAuthProvider>
          </div>
          <div className="registration__delimiter">
            <span>or sign in with email</span>
          </div>
          <form
            className="login__form"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <Controller
              control={control}
              name="email"
              render={({ field: { name, onBlur, value, ref } }) => (
                <FormInput
                  ref={ref}
                  placeholder="Enter your email address"
                  error={errors?.email?.message}
                  name={name}
                  value={value}
                  disabled={isProcessingRequest}
                  onBlur={onBlur}
                  onChange={handleEmailInput}
                />
              )}
            />
            <FormButton
              className="login__button"
              type="primary"
              htmlType="submit"
              loading={isProcessingRequest}
              disabled={!isFormValid || isProcessingRequest}
            >
              Send magic link
            </FormButton>
            <span className="login__link">
              Don't have an account?
              <NavLink to={APP_ROUTES.registration}>Register here</NavLink>
            </span>
          </form>
        </div>
      </div>
      <div className="login__column--right login__column">
        <ExtensionInfo />
      </div>
    </div>
  );
};
