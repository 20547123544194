import React, { useState, useEffect } from "react";
import { usersApi } from "../../api";
import { APP_ROUTES, IUser } from "../../data-access";
import { FormButton, FormSelect, InformationBlock, Panel, Spinner } from "../../ui";
import { Jobs } from "./jobs";
import "./ai-job-searcher.scss";
import { Logs } from "./logs";
import { JobSettingsForm } from "./job-settings-form";
import { researcherApi } from "../../api/job-researcher";
import { JobSearcherStatistics } from "./job-searcher-statistics";
import { useNavigate } from "react-router-dom";
import { JobBotStatus } from "./job-bot-status";
import { Tabs, TabsProps } from "antd";
import { FaPlay } from "react-icons/fa";

const baseClass = "job-searcher";
const options = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

export const AIJobSearcher = (): JSX.Element => {
  const navigate = useNavigate();

  const [isPaid, setIsPaid] = useState<boolean>(false);
  const [settings, setSettings] = useState<any>({});
  const [stats, setStats] = useState<any>({
    open_jobs_count: 0,
    approved_jobs_count: 0,
    skipped_jobs_count: 0,
    approved_percent: 0,
  });
  const [isProcessingRequest, setIsProcessingRequest] = useState<boolean>(true);
  const [isStart, setIsStart] = useState<boolean>(false);
  const [bidFilter, setBidFilter] = useState<string>("");

  useEffect(() => {
    setIsProcessingRequest(true);
    usersApi
      .getMe()
      .then((response: { data: IUser }) => {
        setIsPaid(response.data.bot_subscription_is_active);
        if (response.data.bot_subscription_is_active) {
          getTaskById();
        }
      })
      .finally(() => setIsProcessingRequest(false));
  }, []);

  const getTaskById = () => {
    setIsProcessingRequest(true);
    researcherApi
      .getJobResearcherTaskById("1")
      .then((response: { data: any }) => {
        const settingData = response.data.user_data;

        if (settingData) {
          const dataSettings = {
            login: settingData.UPWORK_LOGIN,
            password: settingData.UPWORK_PASSWORD,
            secret_answer: settingData.UPWORK_QUESTION_ANSWER,
            search_url: settingData.UPWORK_SEARCH_URL,
            name: settingData.FREELANCER_NAME,
            title_profile: settingData.FREELANCER_TITLE_PROFILE,
            description_profile: settingData.FREELANCER_DESCRIPTION_PROFILE,
            availability: settingData.FREELANCER_AVAILABILITY,
            skills: settingData.FREELANCER_SKILLS,
            chat_id: settingData.TELEGRAM_CHAT_ID,
            evaluation_criteria: settingData.EVALUATION_CRITERIA,
            filter: settingData.IMPORTANT_FILTER,
          };

          setSettings(dataSettings);
        }
      })
      .catch((error) => {})
      .finally(() => setIsProcessingRequest(false));
  };

  const setNewSettings = (data: any) => {
    if (data && data.updated) {
      getTaskById();
    }
  };

  const changeStats = (data: any) => {
    setStats(data);
  };

  const changeStart = (value: boolean) => {
    setIsStart(value);
  };

  const handleToolsAndAppsClick = (): void => {
    navigate(APP_ROUTES.toolsAndApps);
  };

  const handleVideoClick = (): void => {
    window.open("https://youtu.be/apjJ0-IH0gU?si=calWvlB8WIVa_WrP", "_blank");
  };

  const handleArtcileClick = (): void => {
    window.open(
      "https://help.upwex.io/en/categories/ai-job-researcher/",
      "_blank"
    );
  };

  const handleFilterChange = (value: string): void => {
    setBidFilter(value);
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Jobs",
      children: (
        <>
          <Panel
            title="Jobs"
            className={`profile-stats-panel ${
              isProcessingRequest ? "is-loading" : ""
            }`}
            nodeInTitleRow={
              <div className={`${baseClass}__filters`}>
                <label>Matched Jobs:</label>
                <FormSelect
                  value={bidFilter}
                  disabled={isProcessingRequest}
                  placeholder="Please select"
                  options={options}
                  onChange={handleFilterChange}
                />
              </div>
            }
          >
            <Jobs
              isLoading={isProcessingRequest}
              emitStatistics={changeStats}
              bidFilter={bidFilter}
            />
          </Panel>

          <Panel
            title="Activity"
            className={`profile-stats-panel ${
              isProcessingRequest ? "is-loading" : ""
            }`}
          >
            <Logs isLoading={isProcessingRequest} />
          </Panel>
        </>
      ),
    },
    {
      key: "2",
      label: "Settings",
      children: (
        <Panel
          title="Settings"
          className={`profile-stats-panel ${
            isProcessingRequest ? "is-loading" : ""
          }`}
        >
          <JobSettingsForm
            isFetching={isProcessingRequest}
            onChange={setNewSettings}
            settings={settings}
            isStart={isStart}
          />
        </Panel>
      ),
    },
  ];

  return isProcessingRequest ? (
    <Panel title="" className={`is-loading loading-template`}>
      <Spinner />
    </Panel>
  ) : !isPaid ? (
    <div className={`${baseClass}__not-paid`}>
      <Panel title="">
        <div className={`${baseClass}__preview`}>
          <div className={`${baseClass}__preview-content`}>
            <h3>AI Job Researcher</h3>
            <p>
              Automatically finds and alerts you to the best job <br /> matches
              based on your skills and preferences.
            </p>
            <div className={`${baseClass}__buttons`}>
              <FormButton
                className={`${baseClass}__button`}
                onClick={handleToolsAndAppsClick}
              >
                Connect
              </FormButton>
              <FormButton
                className={`${baseClass}__button`}
                onClick={handleVideoClick}
              >
                <FaPlay /> Watch demo
              </FormButton>
            </div>
            <span>
              To start using{" "}
              <span
                className={`${baseClass}__link`}
                onClick={handleArtcileClick}
              >
                AI Job Researcher
              </span>
              , you need to <br /> connect it on the{" "}
              <span
                className={`${baseClass}__link`}
                onClick={handleToolsAndAppsClick}
              >
                Tools & Apps
              </span>{" "}
              page.
            </span>
          </div>
          <div className={`${baseClass}__preview-img`}>
            <img src="ai-bg.png" alt="" />
          </div>
        </div>
      </Panel>
    </div>
  ) : (
    <div className={baseClass} style={{ paddingTop: "24px" }}>
      <JobSearcherStatistics isLoading={isProcessingRequest} stats={stats} />

      <InformationBlock>
        Recently, we have noticed instability in the AI Job Researcher. Our team is actively working on resolving this issue, and we are doing our best to restore the service to full functionality as soon as possible. Thank you for your patience, and we apologize for any inconvenience.
      </InformationBlock>

      <JobBotStatus
        isFetching={isProcessingRequest}
        settings={settings}
        onChange={changeStart}
      />

      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};

export default AIJobSearcher;
